import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(main)/store/components/items/store-contact.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollArea"] */ "/app/src/components/ui/scroll-area.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/ui/separator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layouts/main/main-header.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/layouts/main/main-layout.module.css");
